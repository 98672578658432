import { MainEventCard, Typography } from '@decub8/ui';
import dayjs from 'dayjs';

import { ProjectContextProvider } from '@src/components/Project/context';
import { Project } from '@src/ts/interfaces';

import { useMainEventCard } from './hooks';

export enum DisplayStatus {
    Ongoing = 'Ongoing',
    Upcoming = 'Upcoming',
}

export const MainEventCardComponent: React.FC<{
    next_project_event: Project;
    className?: string;
}> = ({ next_project_event, className }) => {
    const props = useMainEventCard(next_project_event);

    const event = next_project_event?.events[0];
    const now = dayjs();
    const start_date = dayjs(Number(event?.start_date) * 1000);

    const display_status =
        now.isBefore(start_date) || !event.contract.address
            ? DisplayStatus.Upcoming
            : DisplayStatus.Ongoing;

    return (
        <div className={className}>
            <Typography allBold size="3xl" className="mb-[30px]">
                {display_status === DisplayStatus.Ongoing
                    ? 'Happening now'
                    : 'Happening soon'}
            </Typography>

            <MainEventCard {...props} />
        </div>
    );
};

export const MainEventCardContainer: React.FC<{
    next_project_event: Project;
    className?: string;
}> = ({ next_project_event, className }) => {
    return (
        <ProjectContextProvider
            project={next_project_event}
            event={
                next_project_event.events[0]
                    ? {
                          ...next_project_event.events[0],
                          id: Number(next_project_event.events[0]?.id), // need to id convert to number
                      }
                    : undefined
            }
            children={
                <MainEventCardComponent
                    next_project_event={next_project_event}
                    className={className}
                />
            }
        />
    );
};

import { BadgeColors } from '@decub8/ui/dist/organisms/types';

import { CONTENT } from '@src/config';
import { BADGE_COLOR, EventChipStatus } from '@src/ts/constants';

export const getFillerCardProps = () => ({
    logo_url: CONTENT.notAnnouncedSquareLogo,
    handleCardClick: () => window.open(CONTENT.telegramUrl, '_blank'),
    chips: [
        {
            text: EventChipStatus.ComingSoon,
            badge_color: BADGE_COLOR[EventChipStatus.ComingSoon] as BadgeColors,
        },
    ],
    heading_text: 'Coming soon',
    card_data: [
        { label: `Event date`, values: [{ value: 'TBA' }] },
        { label: 'Total allocation', values: [{ value: 'TBA' }] },
        { label: 'Event token price', values: [{ value: 'TBA' }] },
    ],
    image_url: CONTENT.upcoming_teaser,
    network_logo:
        'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/content/Group%20229062.svg',
    has_cut_corners: CONTENT.hasCutProjectCards,
    announced: true,
});

import { EventStage } from '@decub8/ui';
import { BadgeColors } from '@decub8/ui/dist/organisms/types';
import dayjs, { Dayjs } from 'dayjs';
import duration, { Duration } from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';

import { IDO_REG_CUTOFF_MINS } from '@src/constants';
import {
    BADGE_COLOR,
    ChipType,
    CrowdfundingType,
    EventType,
} from '@src/ts/constants';
import { MarketingEvent, ProjectEvent } from '@src/ts/interfaces';
import { getEventEndDate } from '@src/utils/getters';

dayjs.extend(duration);
dayjs.extend(utc);

export const isMarketingEventLive = (event?: MarketingEvent): boolean => {
    const now = dayjs.utc();
    const start_date = dayjs.unix(Number(event?.start_date)).utc();
    const end_date = dayjs.unix(Number(event?.end_date)).utc();

    return now.isAfter(start_date) && now.isBefore(end_date);
};

export const isMarketingEventFinished = (event?: MarketingEvent): boolean => {
    const now = dayjs.utc();
    const end_date = dayjs.unix(Number(event?.end_date)).utc();

    return now.isAfter(end_date);
};

export const getFormattedTimeLeftHours = (
    time_left: Duration,
): string | JSX.Element => {
    const is_under_60_seconds = isDurationLessThan60Seconds(time_left);
    const hours = Math.floor(time_left.asHours());
    const minutes = time_left.minutes();
    const seconds = time_left.seconds();

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return (
        <>
            {is_under_60_seconds ? (
                <span className="animate-flash">
                    {formattedHours}:{formattedMinutes}:{formattedSeconds}
                </span>
            ) : (
                `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
            )}
        </>
    );
};

export const getFormattedTimeLeftDays = (time_left: Duration) => {
    const days = Math.ceil(time_left.asDays());

    return `${days} ${days === 1 ? 'Day' : 'Days'}`;
};

export const isTimeLeftLessThan24Hours = (time_left: Duration) => {
    return time_left.asHours() < 24;
};

export const getFormattedTimeLeft = (time_left: Duration) => {
    return isTimeLeftLessThan24Hours(time_left)
        ? getFormattedTimeLeftHours(time_left)
        : getFormattedTimeLeftDays(time_left);
};

export const isWithin60SecondsBefore = (date: Dayjs) => {
    const now = dayjs();
    const sixtySecondsBefore = date.subtract(60, 'seconds');

    // check if the current time is within 60 seconds before the passed-in date
    return now.isAfter(sixtySecondsBefore) && now.isBefore(date);
};

export const getEventStage = (
    event: ProjectEvent,
    is_hardcap_met: boolean,
): EventStage => {
    const today = dayjs();
    const start = dayjs(Number(event?.start_date) * 1000);
    const end_date = getEventEndDate(event);
    const has_contract = !!event?.contract.address || !!event?.contract.abi;

    // check if the event is complete
    if (has_contract && (today.isAfter(end_date) || is_hardcap_met)) {
        return EventStage.Complete;
    }

    // check if the event is in the snapshot period
    const cutoff = start.subtract(
        event?.type === EventType.Crowdfunding ? IDO_REG_CUTOFF_MINS : 0,
        'minutes',
    );
    const has_reached_cutoff = today.isAfter(cutoff);

    if (has_reached_cutoff && today.isBefore(start)) {
        return EventStage.SnapshotPeriod;
    }

    // check if the event is in the whitelist open stage
    if (has_contract && today.isBefore(start)) {
        return EventStage.WhitelistOpen;
    }

    // check if the event is in the GA/FCFS open stage (i.e., it’s ongoing but not complete)
    if (
        (today.isSame(start) || today.isAfter(start)) &&
        today.isBefore(end_date) &&
        has_contract
    ) {
        return EventStage.GaFcfsOpen;
    }

    // if none of the above, it's in the pre-whitelist stage (upcoming event)
    return EventStage.PreWhitelist;
};

export const getChips = (
    type: ChipType | CrowdfundingType | EventType,
    event_status?: string,
) => {
    const chip_type = ChipType[type] as string;

    const chips = [
        {
            text: chip_type,
            badge_color: 'purple' as BadgeColors,
        },
    ];

    // only add the second chip if event_status is defined
    if (event_status) {
        chips.push({
            text: event_status,
            badge_color: BADGE_COLOR[event_status] as BadgeColors,
        });
    }

    return chips;
};

export const isDurationInThePast = (duration: Duration) => {
    return duration.asMilliseconds() < 0;
};

export const isDurationLessThan60Seconds = (duration) => {
    return Math.abs(duration.asSeconds()) < 60;
};
